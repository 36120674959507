import { Component } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import moment from 'moment';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent {
  times = [];
  type: string;
  interval: 15 | 5 = 15;
  callback: (time: string, type: string) => {};

  constructor(
    private popoverCtrl: PopoverController,
    navParams: NavParams,
  ) {
    this.type = navParams.get('type');

    this.setInterval(15);
  }

  setInterval(number: 15 | 5) {
    this.interval = number;
    const isAmPm: boolean = moment().format('LT').length > 5;
    const minutes =
      number === 15
        ? ['00', '15', '30', '45']
        : ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

    let suffix = isAmPm ? ' AM' : '';

    this.times = [];
    for (let h = 0; h < 24; h++) {
      if (isAmPm && h >= 12) {
        suffix = ' PM';
      }
      let hour = h || (isAmPm ? 12 : 0);
      if (isAmPm && hour > 12) {
        hour = hour - 12;
      }
      for (const m of minutes) {
        this.times.push({
          id: `${hour}-${m}-${suffix.replace(' ', '').toLowerCase()}`,
          value: `${hour}:${m}${suffix}`,
        });
      }
    }
  }

  didClick(time: string) {
    this.popoverCtrl.dismiss();
    this.callback(time, this.type);
  }
}
