<ion-content>
  <ion-list>
    <ion-item *ngFor="let opt of times" button="true" (click)="didClick(opt.value)" id="time-picker-{{ opt.id }}">
      <ion-label>
        {{ opt.value }}
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-label>Interval:</ion-label>
      <ion-button
        fill="clear"
        color="dark"
        (click)="setInterval(5)"
        [ngClass]="{ 'pill-button-active': interval === 5 }"
      >
        5 minutes
      </ion-button>
      <ion-button
        fill="clear"
        color="dark"
        (click)="setInterval(15)"
        [ngClass]="{ 'pill-button-active': interval === 15 }"
      >
        15 minutes
      </ion-button>
    </ion-item>
  </ion-list>
</ion-content>
